import React, {  useEffect, useState } from 'react'
import ConnectButton from './helper/ConnectButton';
import { nft_address } from '../hooks/constant';



export default function Main() {
    
    const [nftImage,setNftImage] = useState('');
    const [jsonno,setJsonno] = useState(1);
    const [counter, setCounter] = useState(1);

    useEffect(()=>{
         
        handleChangeImage();
    },[])
    
    const handleChangeImage = async() =>{
        
        let image_no = Math.floor(Math.random()*10000);
        const images = await require(`../img/${image_no}.png`);
        setNftImage(images);
        setJsonno(image_no);
    }


    const handleMinusCounter = () => {
        if (counter > 1) {
            setCounter(prevCounter => prevCounter - 1);
        }
    }

    const handlePlusCounter = () => {
        if(counter < 2 ){
            setCounter(prevCounter => prevCounter + 1);
        }
    }



    return (
        <section className="banner-section">
            <div className="container">
                <div className="row ">
                    <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                        <img src={nftImage} alt="" className="img-logo" />
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-2">

                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8">
                        <div className="card mt-4">
                            <div className="card-body">
                                <p className="headings">{counter}/2</p>
                                <p className="address text-center">{nft_address}</p>
                                <p className="sub-title">1 CryptoBoy costs 0.05 BNB</p>
                                <p className="fees">Excluding gas fees.</p>
                                <ConnectButton handleChangeImage={handleChangeImage} counter={counter} handleMinusCounter={handleMinusCounter} handlePlusCounter={handlePlusCounter}  jsonno={jsonno} />
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-2">
                        {/* <div className="img-wrapper">
                        <img src={example} alt="img-gif1" className="img-gif" style={{"transform": "scaleX(-1)"}} />
                    </div> */}
                    </div>
                </div>
                <div className="row justify-content-center mt-5">
                    <div className="col-sm-12 col-md-10 col-lg-10 text-center">
                        <p>Please make sure you are connected to the right network (BSC Mainnet) and the correct address. Please note: Once you make the purchase, you cannot undo this action.</p>
                        <p>You can see your NFT by adding contract address and decimal info. Copy contract address above ({nft_address.toString().substr(0, 3)}...) and paste to custom token info in your wallet. (DECIMAL is 0)</p>
                        <p>We have set the gas limit to 600000 for the contract to successfully mint your NFT. We recommend that you don't lower the gas limit. Maximum purchase of 2 NFTs per transaction</p>
                    </div>
                </div>

                <div className="footer">
                    <p className="rights">All Rights reserved. luckybox.space</p>
                </div>
            </div>
        </section>
    )
}
