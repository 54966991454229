import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError
} from "@web3-react/injected-connector";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { injected } from "../../hooks/connectors";
import { useEagerConnect, useInactiveListener } from "../../hooks/hooks";
import BuyButton from "./BuyButton";



export const ConnectButton = function (props) {
  const context = useWeb3React();
  const { connector, chainId, activate, deactivate, active, error } = context;

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  function getErrorMessage(error) {

    if (error instanceof NoEthereumProviderError) {
      toast.error("Metamask not deteced");
    }
    if (error instanceof UnsupportedChainIdError) {
      return "Switch Network Bsc Mainnet";
    }

    deactivate(injected);
  }

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  const activating = (connection) => connection === activatingConnector;
  const connected = (connection) => connection === connector;


  return (

    <>

      {
        error &&
        <div className="text-center my-3">
          <p className="text-center">Connect to the Binanace network</p>
          <button className="btn btn-main" onClick={() => {
            setActivatingConnector(injected);
            activate(injected);
          }}>
            <div className="font-bold self-center">{getErrorMessage(error)}</div>
          </button>
        </div>
      }
      {!error &&
        <>

          {connected(injected) && typeof chainId === 'undefined' &&
            <div className="text-center my-3">
              <p className="text-center">Connect to the Binance network</p>
              <button className="btn btn-main">
                <div className="font-bold self-center">Switch Binance network</div>
              </button>
            </div>

          }
          {(active || !error) && connected(injected) &&
            <>
              <p className="text-center">Click buy to mint your NFT.</p>
              <BuyButton handleChangeImage={props.handleChangeImage} counter={props.counter} handleMinusCounter={props.handleMinusCounter} handlePlusCounter={props.handlePlusCounter}  jsonno={props.jsonno} />
            </>
          }

          {!connected(injected) &&
            <div className="text-center my-3">
              <p className="text-center">Connect to the Binance</p>
              <button className="btn btn-main" onClick={() => {
                setActivatingConnector(injected);
                activate(injected);
              }}>
                {activating(injected) && <div className="font-bold self-center">Connecting...</div>}
                {!activating(injected) && <div className="font-bold self-center">Connect wallet</div>}
              </button>
            </div>
          }
        </>
      }
    </>
  );
};

export default ConnectButton;
