import { Contract } from '@ethersproject/contracts';
import { JsonRpcProvider } from '@ethersproject/providers';
import { nft_address, nft_abi } from '../../hooks/constant';
import { RPC_URLS } from '../../hooks/connectors';
import { RPC } from '../../hooks/constant';


export let singer =  new JsonRpcProvider(RPC_URLS[RPC]);

export async function getContract (library = null) {
    try{
        singer = library ? library.getSigner() : singer;
        let contract = new Contract(nft_address, nft_abi);
        return contract.connect(singer);
    }
    catch(err){
        console.log(err.message);
        return false;
    }
    
}






